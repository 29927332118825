<template>
  <div>
       <v-card>
        <v-toolbar class="card-title">
          <v-toolbar-title >
              Add New Tenant
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn depressed class="btn-add" @click="dialog_add=true"><v-icon left>mdi-plus</v-icon>Add New Tenant </v-btn>
       </v-toolbar>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left"> Sr.No.</th>
                  <th class="text-left"> Tenant Name</th>
                  <th class="text-left"> Tenant ID</th>
                  <th class="text-left"> Domain Name</th>
                  <th class="text-left"> Logo</th>
                  <th class="text-left"> Status</th>
                  <th class="text-left"> Edit</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,i) in tenant_list" :key="item.name">
                  <td>{{ i+1}}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.tenantid }}</td>
                  <td>{{ item.domainname }}</td>
                  <td>
                      <span v-if="item.logoname"><a :href="item.logo" target="blank"><v-icon>mdi-eye</v-icon></a></span>
                      <span v-else><v-icon>mdi-eye-off</v-icon></span>
                  </td>
                  <td>
                    <span v-if="item.isactive"><v-icon style="color:green; cursor: -webkit-grab; cursor: grab;"> mdi-checkbox-marked-circle</v-icon></span>
                    <span v-else><v-icon style="color:red;cursor: -webkit-grab; cursor: grab;">mdi-close-circle</v-icon></span>
                  </td>
                  <td><v-btn icon color="indigo" @click="getdata(item.tc_id)"><v-icon>mdi-pencil</v-icon></v-btn></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
       </v-card>
    <!-- Add Tenant -->
    <v-dialog  v-model="dialog_add" persistent max-width="600px" >
      <v-card>
        <v-card-title class="dig-title">
          <span class="headline">Add New Tenant</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field label="Enter Tenant Name" v-model="tenant_name" ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field label="Enter Tenant Id" v-model="tenant_id" ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field label="Enter Domain Name" v-model="domain_name" ></v-text-field>
              </v-col>
              <v-col cols="8" sm="8" md="8">
                <v-file-input label="Select Logo File" v-model="logo_file" ></v-file-input>
              </v-col>
              <v-col cols="4" sm="4" md="4">
                 <v-checkbox v-model="isactive" label="IsActive" color="indigo" value="indigo" hide-details></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="btn-close" text @click="dialog_add = false"> Close</v-btn>
          <v-btn class="btn-add" text @click="savedata()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
     <!-- Eidt Tenant -->
    <v-dialog  v-model="dialog_edit" persistent max-width="600px" >
      <v-card>
        <v-card-title class="dig-title">
          <span class="headline">EditTenant</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field label="Enter Tenant Name" v-model="tenant_name_edit" ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field label="Enter Tenant Id" v-model="tenant_id_edit" ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field label="Enter Domain Name" v-model="domain_name_edit" ></v-text-field>
              </v-col>
              <v-col cols="8" sm="8" md="8">
                <v-file-input label="Select Logo File" v-model="logo_file_edit" ></v-file-input>
              </v-col>
              <v-col cols="4" sm="4" md="4">
                 <v-checkbox v-model="isactive_edit" label="IsActive" color="indigo" ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="btn-close" text @click="dialog_edit = false"> Close</v-btn>
          <v-btn class="btn-add" text @click="saveeditdata()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Snackbar -->
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="#FFF" text v-bind="attrs" @click="snackbar = false" > <v-icon>mdi-close</v-icon></v-btn>
      </template>
    </v-snackbar>
  </div> <!-- /. root div -->
</template>
<script>
import axios from 'axios'
export default {
  components:{},
  data: () => ({
    dialog_add:false,
    dialog_edit:false,
    tenant_name:null,
    tenant_id:null,
    domain_name:null,
    logo_file:null,
    logo_file_edit:null,
    tenant_list:null,
    snackbar: false,
    text: null,
    timeout: 2000,
    color:null,
    isactive:[true],
    isactive_edit:null,
    tenant_name_edit:null,
    tenant_id_edit:null,
    domain_name_edit:null,
    tcid:null
  }),
  mounted(){
    this.onload()
  },
  methods: {
    onload(){
      axios.post('/SuperAdmin/getTenantData').then(res=>{
        if(res.data.status=='YES'){
          this.tenant_list=res.data.tenantList
        }
      })
    },
    savedata(){
      if(this.tenant_name==null){
        this.showsnackbar("Enter Tenant Name", "success", 5000);
      }else if(this.tenant_id==null){
        this.showsnackbar("Enter Tenant Id", "success", 5000);
      }else if(this.domain_name==null){
        this.showsnackbar("Enter Domain Name", "success", 5000);
      }else{
      let formData = new FormData();
      formData.append("logo_file", this.logo_file);
      formData.append("tcid", this.tcid);
      formData.append("tenant_name", this.tenant_name);
      formData.append("tenant_id", this.tenant_id);
      formData.append("domain_name", this.domain_name);
      axios.post('/SuperAdmin/saveTenantData',formData,{
      headers: {
          "Content-Type": "multipart/form-data"
      }
      }).then(res=>{
        if(res.data.status=='YES'){
          this.dialog_add=false
          this.showsnackbar("Tenant add Successfully!!!", "success", 5000);
          this.rest()
        }else
        {
           this.showsnackbar("Something went wrong!!!", "success", 5000);
        }
      })
      }
    },
    rest(){
    this.tenant_name=null,
    this.tenant_id=null,
    this.domain_name=null,
    this.logo_file=null
    },
    showsnackbar(msg, colors, to) {
        this.snackbar=true
        this.timeout=to
        this.color=colors
        this.text=msg
    },
    getdata(tcid){
      const data={
        tcid:tcid
      };
      axios.post('/SuperAdmin/geteditTenantData',data).then(res=>{
        if(res.data.status=='YES'){
          this.isactive_edit=res.data.isactive
          this.tenant_name_edit=res.data.name
          this.tenant_id_edit=res.data.tenantid
          this.domain_name_edit=res.data.domainname
          this.tcid=res.data.tc_id
          this.dialog_edit=true
        }else
        {
           this.showsnackbar("Something went wrong!!!", "success", 5000);
        }
      })
    },
    saveeditdata(){
      if(this.tenant_name_edit==null){
        this.showsnackbar("Enter Tenant Name", "success", 5000);
      }else if(this.tenant_id_edit==null){
        this.showsnackbar("Enter Tenant Id", "success", 5000);
      }else if(this.domain_name_edit==null){
        this.showsnackbar("Enter Domain Name", "success", 5000);
      }else{
      let formData = new FormData();
      formData.append("logo_file", this.logo_file_edit);
      formData.append("tenant_name", this.tenant_name_edit);
      formData.append("tenant_id", this.tenant_id_edit);
      formData.append("domain_name", this.domain_name_edit);
      formData.append("tcid", this.tcid);
      formData.append("isactive", this.isactive_edit);
      axios.post('/SuperAdmin/saveTenantData',formData,{
      headers: {
          "Content-Type": "multipart/form-data"
      }
      }).then(res=>{
        if(res.data.status=='YES'){
          this.dialog_edit=false
          this.showsnackbar("Tenant Update Successfully!!!", "success", 5000);
          this.restedit()
          this.onload()
        }else
        {
           this.showsnackbar("Something went wrong!!!", "success", 5000);
        }
      })
      }
    },
    restedit(){
      this.isactive_edit=null
      this.tenant_name_edit=null
      this.tenant_id_edit=null
      this.domain_name_edit=null
      this.tcid=null
    }
  },
  watch: {},
};
</script>
<style scoped>
.card-title{
  background: rgb(13,12,12);
  background: linear-gradient(90deg, rgba(13,12,12,1) 0%, rgba(84,82,84,1) 50%, rgba(184,181,183,1) 98%); 
  color: #FFF;  
}
.dig-title{
  background: rgb(6,1,64);
  background: linear-gradient(90deg, rgba(6,1,64,1) 0%, rgba(2,5,135,1) 49%, rgba(213,216,232,1) 100%);
  color: #FFF;  
}
.btn-add{
  background: rgb(2,120,136);
  background: linear-gradient(90deg, rgba(2,120,136,1) 0%, rgba(1,140,157,1) 49%, rgba(117,232,241,1) 100%);
  color: #FFF;  
}
.btn-close{
  background: rgb(136,2,2);
  background: linear-gradient(90deg, rgba(136,2,2,1) 0%, rgba(157,1,1,1) 49%, rgba(240,172,172,1) 100%);
  color: #FFF; 
}
@media (min-width: 320px) and (max-width: 767.98px) { 

}
@media (min-width: 768px) and (max-width: 991.98px) {

}
@media (min-width: 992px) and (max-width: 1024px) { 
}

</style>
